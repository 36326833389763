import { Injectable } from '@angular/core';
import { EventApiService } from './api';
import { EventParam } from '@models/event-api';
import { Observable } from 'rxjs';
import { GetAllEventsResult } from '@models/getAllEventsResult';
import { GetEventByIdResult } from '@models/getEventByIdResult';

@Injectable({ providedIn: 'root' })
export class EventService {
  constructor(private eventApiService: EventApiService) {}

  public getEvent(id: number): Observable<GetEventByIdResult> {
    return this.eventApiService.getEvent(id);
  }

  getEvents(eventParams: EventParam): Observable<GetAllEventsResult> {
    eventParams.limit = 1000;
    return this.eventApiService.getEvents(eventParams);
  }
}
